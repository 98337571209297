var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data.properties.filed_content != 'Hide')?_c('div',{style:(_vm.getStyle)},[_c('el-row',{attrs:{"align":"middle"}},[(
        !_vm.data.properties.hideLabel || _vm.data.properties.filed_content != 'Hide'
      )?_c('span',[(_vm.hasLabel)?_c('label',{class:{ 'highlight-border': _vm.highlight },attrs:{"for":"text"}},[_vm._v(" "+_vm._s(_vm.label || _vm.data.label)+" ")]):_vm._e(),(_vm.data.validations.required && _vm.hasLabel)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e()]):_vm._e(),_c('el-col',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1),(_vm.data.filled_by === 'RECEIVER' || _vm.data.filled_by === 'SENDER')?_c('span',[_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPosq ? 12 : 24}},[(
            !_vm.isView &&
            _vm.data.properties.filed_content != 'Hide' &&
            _vm.data.showLink
          )?_c('el-input',{attrs:{"placeholder":"Enter Video Link"},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(_vm.iframeURL)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"id":"iframe"}},[_c('iframe',{style:(_vm.getStyle()),attrs:{"src":_vm.iframeURL,"frameborder":"5","allowfullscreen":""}})]):_c('div',[_c('img',{staticClass:"inline-block",attrs:{"alt":"Avatar","src":require("../../../../src/assets/img/thumbnail.jpg"),"height":this.data.height - 30,"width":this.data.width}})]),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"video/*"},on:{"change":function($event){return _vm.handleVideoFileUpload($event.target.files[0])}}}),(
            !_vm.isView &&
            _vm.data.properties.filed_content !== 'Hide' &&
            _vm.data.filled_by === 'RECEIVER'
          )?_c('el-button',{staticClass:"upload-file upload-button",attrs:{"loading":_vm.loading},on:{"click":_vm.enableDialog}},[_vm._v(" Upload ")]):_vm._e(),(
            !_vm.isView &&
            _vm.data.properties.filed_content !== 'Hide' &&
            _vm.data.filled_by === 'RECEIVER' &&
            _vm.form[_vm.data.key]
          )?_c('el-button',{staticStyle:{"background-color":"ivory","max-width":"27px","margin-left":"5px"},attrs:{"type":"plain","size":"mini","circle":""},on:{"click":function($event){return _vm.deleteImage()}}},[_c('img',{attrs:{"width":"12px","src":require("@/assets/Trash.svg")}})]):_vm._e()],1)],1):_c('span',[_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPosq ? 12 : 24}},[(_vm.iframeURL)?_c('div',{attrs:{"id":"iframe"}},[_c('iframe',{style:(_vm.getStyle()),attrs:{"src":_vm.iframeURL,"frameborder":"0","allowfullscreen":""}})]):_c('div',[_c('img',{staticClass:"inline-block",attrs:{"alt":"Avatar","src":require("../../../../src/assets/img/thumbnail.jpg"),"height":this.data.height - 30,"width":this.data.width}})]),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"video/*"},on:{"change":function($event){return _vm.handleVideoFileUpload($event.target.files[0])}}}),(!_vm.isView && _vm.data.properties.filed_content !== 'Hide')?_c('el-button',{staticClass:"upload-file upload-button",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.enableDialog}},[_vm._v(" Upload ")]):_vm._e(),(
            !_vm.isView &&
            _vm.data.properties.filed_content !== 'Hide' &&
            _vm.form[_vm.data.key]
          )?_c('el-button',{staticStyle:{"background-color":"ivory","max-width":"27px","margin-left":"5px"},attrs:{"type":"plain","size":"mini","circle":""},on:{"click":function($event){return _vm.deleteImage()}}},[_c('img',{attrs:{"width":"12px","src":require("@/assets/Trash.svg")}})]):_vm._e()],1)],1)],1),_c('dialog-component',{attrs:{"title":'Upload Video',"visible":_vm.showDialogue,"containerWidth":'500px',"containerHeight":'260px'},on:{"before-close":_vm.closeDialog}},[_c('div',[_c('p',[_c('b',[_vm._v("Would you like to upload a video file?")])]),_c('p',[_vm._v("Supported file format: "),_c('strong',[_vm._v(".MP4")])]),_c('p',[_vm._v(" File size: "),_c('strong',[_vm._v(_vm._s(_vm.data.fileSize)+"MB")])])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.closeDialog}},[_vm._v("NO ")]),_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":_vm.openFileUploader}},[_vm._v("YES ")])],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }